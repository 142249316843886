import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HeaderTopBar from '../../../components/common/Header/HeaderTopBar';
import Footer from '../../common/Footer/Footer';
import { useTheme, useMediaQuery } from '@mui/material';
import DownloadTips from '../../common/Header/DownloadTips';

const ContactUsPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      {isMobile && <DownloadTips />}
      <HeaderTopBar />

      <Box sx={{ py: 8, textAlign: 'center' }}>
        <Typography variant="h5" component="h2" sx={{ mb: 4, fontWeight: 'bold' }}>
          Contact Us
        </Typography>
        <Box sx={{ px: 3, maxWidth: '600px', margin: '0 auto', lineHeight: 1.8 }}>
          <Typography variant="body1" sx={{ fontSize: '16px', mb: 2 }}>
            Interested in getting our salad kiosks in your vicinity or looking to order for events?
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '16px', mb: 2 }}>
            Got any questions, feedback, or compliments for us? Feel free to leave us a message or call us at:
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', color: 'primary.main', mb: 2 }}
          >
            3129 8094
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '16px' }}>
            during our operating hours (Mon-Fri, 9am to 9pm) and have a chat with us!
          </Typography>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default ContactUsPage;

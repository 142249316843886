import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HeaderTopBar from '../../../components/common/Header/HeaderTopBar';
import Footer from '../../common/Footer/Footer';
import { useTheme, useMediaQuery } from '@mui/material';
import DownloadTips from '../../common/Header/DownloadTips';

const AboutUsPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      {isMobile && <DownloadTips />}
      <HeaderTopBar />

      <Box sx={{ py: 8, textAlign: 'center' }}>
        <Typography variant="h5" component="h2" sx={{ mb: 6, fontWeight: 'bold' }}>
          About Us
        </Typography>
        <Box sx={{ px: 3, maxWidth: '600px', margin: '0 auto' }}>
          <Typography variant="body1" sx={{ fontSize: '16px', lineHeight: 1.6 }}>
            At Shake Salad, we specialize in healthy, convenient, and affordable ready-to-eat food. 
            Our mission is to bring delicious, nutritious meals right to your doorstep. 
            Here's to healthy living made simple!
          </Typography>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default AboutUsPage;

import colorConfigs from "../../configs/colorConfigs";

const ForgetPasswordPageStyles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100vh',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      padding: '24px',
      height: '100vh',
    },
    headerText: {
      color: colorConfigs.primary,
      fontWeight: 500,
    },
    descriptionText: {
      marginTop: '8px',
    },
    phoneInputContainer: {
      mt: 2,
      width: '100%',
    },
    sendOtpButton: {
      mt: 3,
      mb: 2,
      backgroundColor: colorConfigs.buttons.primary.background,
      '&:hover': {
        backgroundColor: colorConfigs.buttons.primary.background,
      },
    },
    loginLink: {
      textDecoration: 'none',
      color: 'primary.main',
    },
  };
  
  export default ForgetPasswordPageStyles;
  
import { useState } from 'react';
import { Typography, Button, Paper, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import verifyOtpPageStyles from '../../../styles/PublicPage/verifyotppage';
import ShakeSaladLogo from '../../../assets/images/shakesaladlogo.png';
import { introductionPageStyles } from '../../../styles/ProtectedPage/introduction';
import Overall from '../../../assets/images/OverallSequence-ezgif.com-video-to-gif-converter.gif';
import LinkCard from '../../../assets/images/Linkcardwallet-ezgif.com-video-to-gif-converter.gif';
import Unlock from '../../../assets/images/Unlockfull-ezgif.com-video-to-gif-converter.gif';
import Scan from '../../../assets/images/Scanproduct-ezgif.com-video-to-gif-converter.gif';
import Checkout from '../../../assets/images/Checkoutpayments-ezgif.com-video-to-gif-converter.gif';
import { PATH_NAME } from '../../../configs/pathName';
import colorConfigs from '../../../configs/colorConfigs';

const IntroductionPage = () => {
    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    const totalSteps = 5;
    
    const handleNext = () => {
        if (step < totalSteps) {
            setStep(step + 1);
        } else {
            navigate(PATH_NAME.MEMBER);
        }
    };

    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1);
        } else {
            navigate(PATH_NAME.ROOT);
        }
    };

    return (
        <Paper elevation={3} sx={introductionPageStyles.rootPaper}>
            <Box sx={introductionPageStyles.headerBox}>
                <Box>
                    <img
                        alt="Company Logo"
                        src={ShakeSaladLogo}
                        style={verifyOtpPageStyles.logoImage}
                    />
                </Box>
            </Box>
            <Box sx={introductionPageStyles.contentBox}>
                {step === 1 && (
                    <>
                        <Typography
                            sx={{ fontSize: '38px', color: colorConfigs.primary, fontWeight: '900', fontFamily: 'Urbanist' }}
                        >
                            Grab & go anytime!
                        </Typography>
                        <Box sx={{ mt: 2, textAlign: 'center' }}>
                            <img
                                src={Overall}
                                alt="Grab and go GIF"
                                style={{ width: '100%', maxWidth: '300px' }}
                            />
                        </Box>
                        <Box sx={{ mt: 4 }}>
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{
                                    backgroundColor: colorConfigs.primary,
                                    color: '#ffffff',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderRadius: 0,
                                    fontFamily: 'Urbanist',
                                    fontWeight: 'bold',
                                    '&:hover': { backgroundColor: colorConfigs.primary, color:'#ffffff' },
                                }}
                            >
                                Show Me How!
                            </Button>
                        </Box>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Box sx={{ display: 'flex', flexDirection:'column', alignItems:'center', gap:2, justifyContent: 'center', width:'100%'}}>
                            <Box
                                sx={{
                                    backgroundColor: colorConfigs.primary,
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontFamily: 'Urbanist'
                                }}
                            >
                                <Typography sx={{ color: 'white' ,fontWeight: 'bold', fontFamily: 'Urbanist'}}>1</Typography>
                            </Box>
                            <Box sx={{
                                
                                textAlign: 'center',
                                backgroundColor: colorConfigs.primary,
                                color: 'white',
                                width: '100%',
                                maxWidth: '300px',
                                margin: '0 auto',
                                padding: '0.5rem',
                                mb: 2,
                                borderRadius: '4px',
                            }}>
                        <Typography sx={{ fontSize:'16px', fontWeight: 'bold', fontFamily: 'Urbanist'}}
                            variant="h6">
                            Set-up your Account
                        </Typography>
                        </Box>
                        </Box>
                        <Box sx={{height:'380px', fontFamily: 'Urbanist'}}>
                        <Typography sx={{ textAlign: 'center', color: colorConfigs.primary, fontWeight: 'bold', fontSize: '12px', fontFamily: 'Urbanist', backgroundColor: '#ffff00', padding:'8px'}}>
                            Link your credit card or top-up $5.00 using PayNow.
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt:2 }}>
                            <img
                                src={LinkCard}
                                alt="Wallet Setup GIF"
                                style={{ width: '100%', maxWidth: '250px' }}
                            />
                        </Box>
                        <Typography sx={{ textAlign: 'center', color: colorConfigs.primary, fontWeight: 'bold', fontSize: '12px', fontFamily: 'Urbanist'}}>
                            For credit cards, there will be a pre-authorization charge of $0.01 that will be credited to your wallet. Subsequent purchases will automatically be deducted from the linked credit card.
                        </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, mt: 2 }}>
                            <Button
                                variant="contained"
                                onClick={handleBack}
                                sx={{
                                    backgroundColor: '#7F7F7F',
                                    color: 'white',
                                    '&:hover': { backgroundColor: '#666666', color:'#ffffff' },
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    borderRadius: 0,
                                    fontFamily: 'Urbanist',
                                    fontWeight: 'bold',
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{
                                    backgroundColor: '#f2f2f2',
                                    color: colorConfigs.secondary,
                                    '&:hover': { backgroundColor: colorConfigs.primary, color:'#ffffff' },
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    borderRadius: 0,
                                    fontFamily: 'Urbanist',
                                    fontWeight: 'bold',
                                }}
                            >
                                Next
                            </Button>
                        </Box>
                    </>
                )}
                {step === 3 && (
                    <>
                        <Box sx={{ display: 'flex', flexDirection:'column', alignItems:'center', gap:2, justifyContent: 'center', width:'100%', fontFamily: 'Urbanist'}}>
                            <Box
                                sx={{
                                    backgroundColor: colorConfigs.primary,
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontFamily: 'Urbanist'
                                }}
                            >
                                <Typography sx={{ color: 'white', fontWeight: 'bold', fontFamily: 'Urbanist'}}>2</Typography>
                            </Box>
                            <Box sx={{
                                textAlign: 'center',
                                backgroundColor: colorConfigs.primary,
                                color: 'white',
                                width: '100%',
                                maxWidth: '300px',
                                margin: '0 auto',
                                padding: '0.5rem',
                                mb: 2,
                                borderRadius: '4px',
                                fontFamily: 'Urbanist'
                            }}>
                        <Typography sx={{fontSize:'16px', fontWeight: 'bold',fontFamily: 'Urbanist'}}
                            variant="h6">
                            How to unlock the fridge?
                        </Typography>
                        </Box>
                        </Box>
                        <Box sx={{height:'380px', fontFamily: 'Urbanist'}}>
                        <Typography sx={{ textAlign: 'center', color: colorConfigs.primary, fontWeight: 'bold', fontSize: '12px', fontFamily: 'Urbanist', backgroundColor: '#ffff00', padding:'8px'}}>
                            Place the QR code on our webapp against the scanner to unlock the fridge
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt:2 }}>
                            <img
                                src={Unlock}
                                alt="Wallet Setup GIF"
                                style={{ width: '100%', maxWidth: '250px' }}
                            />
                        </Box>
                        
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, mt: 2 }}>
                            <Button
                                variant="contained"
                                onClick={handleBack}
                                sx={{
                                    backgroundColor: '#7F7F7F',
                                    color: 'white',
                                    '&:hover': { backgroundColor: '#666666', color:'#ffffff' },
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    borderRadius: 0,
                                    fontFamily: 'Urbanist',
                                    fontWeight: 'bold',
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{
                                    backgroundColor: '#f2f2f2',
                                    color: colorConfigs.secondary,
                                    '&:hover': { backgroundColor: colorConfigs.primary, color:'#ffffff' },
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    borderRadius: 0,
                                    fontFamily: 'Urbanist',
                                    fontWeight: 'bold',
                                }}
                            >
                                Next
                            </Button>
                        </Box>
                    </>
                )}
                {step === 4 && (
                    <>
                        <Box sx={{ display: 'flex', flexDirection:'column', alignItems:'center', gap:2, justifyContent: 'center', width:'100%'}}>
                            <Box
                                sx={{
                                    backgroundColor: colorConfigs.primary,
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography sx={{ color: 'white', fontWeight: 'bold' , fontFamily: 'Urbanist'}}>3</Typography>
                            </Box>
                            <Box sx={{
                                fontWeight: 'bold',
                                textAlign: 'center',
                                backgroundColor: colorConfigs.primary,
                                color: 'white',
                                width: '100%',
                                maxWidth: '300px',
                                margin: '0 auto',
                                padding: '0.5rem',
                                mb: 2,
                                borderRadius: '4px',
                            }}>
                        <Typography sx={{fontSize:'16px', fontWeight: 'bold', fontFamily: 'Urbanist'}}
                            variant="h6">
                            Add your products to cart!
                        </Typography>
                        </Box>
                        </Box>
                        <Box sx={{height:'380px'}}>
                        <Typography sx={{ textAlign: 'center', color: colorConfigs.primary, fontWeight: 'bold', fontSize: '12px', fontFamily: 'Urbanist', backgroundColor: '#ffff00', padding:'8px'}}>
                            Scan each item's barcode to add to cart.
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt:2 }}>
                            <img
                                src={Scan}
                                alt="Wallet Setup GIF"
                                style={{ width: '250px'}}
                            />
                        </Box>
                        
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, mt: 2 }}>
                            <Button
                                variant="contained"
                                onClick={handleBack}
                                sx={{
                                    backgroundColor: '#7F7F7F',
                                    color: 'white',
                                    '&:hover': { backgroundColor: '#666666' },
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    borderRadius: 0,
                                    fontFamily: 'Urbanist',
                                    fontWeight: 'bold',
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{
                                    backgroundColor: '#f2f2f2',
                                    color: colorConfigs.secondary,
                                    '&:hover': { backgroundColor: colorConfigs.primary, color:'#ffffff' },
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    borderRadius: 0,
                                    fontFamily: 'Urbanist',
                                    fontWeight: 'bold',
                                }}
                            >
                                Next
                            </Button>
                        </Box>
                    </>
                )}
                {step === 5 && (
                    <>
                        <Box sx={{ display: 'flex', flexDirection:'column', alignItems:'center', gap:2, justifyContent: 'center', width:'100%'}}>
                            <Box
                                sx={{
                                    backgroundColor: colorConfigs.primary,
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography sx={{ color: 'white', fontWeight: 'bold', fontFamily: 'Urbanist' }}>4</Typography>
                            </Box>
                            <Box sx={{
                                fontWeight: 'bold',
                                textAlign: 'center',
                                backgroundColor: colorConfigs.primary,
                                color: 'white',
                                width: '100%',
                                maxWidth: '300px',
                                margin: '0 auto',
                                padding: '0.5rem',
                                mb: 2,
                                borderRadius: '4px',
                            }}>
                        <Typography sx={{fontSize:'16px', fontWeight: 'bold', fontFamily: 'Urbanist'}}
                            variant="h6">
                            Check out your cart
                        </Typography>
                        </Box>
                        </Box>
                        <Box sx={{height:'380px', fontWeight: 'bold', fontFamily: 'Urbanist'}}>
                        <Typography sx={{ textAlign: 'center', color: colorConfigs.primary, fontWeight: 'bold', fontSize: '12px' , fontFamily: 'Urbanist', backgroundColor: '#ffff00', padding:'8px'}}>
                        Ready to make payment? Pay using your credit card, or your wallet balance!
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt:2 }}>
                            <img
                                src={Checkout}
                                alt="Wallet Setup GIF"
                                style={{ width: '100%', maxWidth: '250px' }}
                            />
                        </Box>
                        
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, mt: 2 }}>
                            <Button
                                variant="contained"
                                onClick={handleBack}
                                sx={{
                                    backgroundColor: '#7F7F7F',
                                    color: 'white',
                                    '&:hover': { backgroundColor: '#666666', color:'#ffffff' },
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    borderRadius: 0,
                                    fontFamily: 'Urbanist',
                                    fontWeight: 'bold',
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{
                                    backgroundColor: colorConfigs.primary,
                                    color: '#ffffff',
                                    '&:hover': { backgroundColor: colorConfigs.primary, color:'#ffffff' },
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    borderRadius: 0,
                                    fontFamily: 'Urbanist',
                                    fontWeight: 'bold',
                                }}
                            >
                                Done!
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Paper>
    );
};

export default IntroductionPage;
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import assets from '../../../assets';
import colorConfigs from '../../../configs/colorConfigs';

const DownloadTips: React.FC = () => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: colorConfigs.primary, color: '#fff', padding: '5px 5px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src={assets.images.logo} alt="App Icon" style={{ width: '20%', height: '20%', marginRight: '10px' }} />
        
        <Typography variant="body1" sx={{ fontSize: '10px' }}>
         Enjoy $3 voucher when you sign up
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <Button 
          variant="contained" 
          sx={{ backgroundColor: '#fff', color: '#12582D', textTransform: 'none', borderRadius: '20px', padding: '5px 10px' ,fontSize:'10px'}}>
          Download
        </Button> */}

        <IconButton onClick={handleClose} sx={{ color: '#fff', marginLeft:0 }}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DownloadTips;

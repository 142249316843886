import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import assets from '../../../assets';
import ResponsiveTopBar from '../Header/ResponsiveTopBar';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderStyles from '../../../styles/PublicPage/header';
import { useAppSelector } from '../../../redux/features/redux-hooks';
import { PATH_NAME } from '../../../configs/pathName';

const HeaderTopBar: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const navigate = useNavigate();
  const location = useLocation();
  const basicUserInfo = useAppSelector((state) => state.authState.basicUserInfo);
  
  const appBarHeight = isMobile ? '60px' : isTablet ? '75px' : '90px';

  const handleNavigation = (path: string) => () => {
    navigate(path);
  };

  const handleLogoClick = () => {
    navigate(PATH_NAME.ROOT);
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <AppBar position="sticky" sx={HeaderStyles.appBar}>
      <Toolbar
        sx={{
          ...HeaderStyles.toolbar,
          height: appBarHeight,
        }}
      >
        {isMobile || isTablet ? (
          <ResponsiveTopBar isMobile={isMobile} />
        ) : (
          <>
            <Box sx={{ ...HeaderStyles.flexBox, ...HeaderStyles.flex40 }}>
              <Button
                color="inherit"
                onClick={handleNavigation('/')}
                sx={{
                  ...HeaderStyles.button,
                  ...HeaderStyles.getActiveButtonStyle(isActive('/')),
                }}
              >
                Home
              </Button>
              <Button
                color="inherit"
                onClick={handleNavigation('/about')}
                sx={{
                  ...HeaderStyles.button,
                  ...HeaderStyles.getActiveButtonStyle(isActive('/about')),
                }}
              >
                About
              </Button>
            </Box>

            <Box sx={{ ...HeaderStyles.flexBox, ...HeaderStyles.flex20 }}>
              <Button onClick={handleLogoClick} sx={HeaderStyles.logoButton}>
              <img src={assets.images.logo} alt="Logo" style={HeaderStyles.logo} />
              </Button>
            </Box>

            <Box sx={{ ...HeaderStyles.flexBox, ...HeaderStyles.flex40, ...HeaderStyles.flexEnd }}>
              <Button
                color="inherit"
                onClick={handleNavigation('/contact')}
                sx={{
                  ...HeaderStyles.button,
                  ...HeaderStyles.getActiveButtonStyle(isActive('/contact')),
                }}
              >
                Contact
              </Button>
              <Button
                color="inherit"
                onClick={handleNavigation(
                  basicUserInfo ? PATH_NAME.MEMBER : PATH_NAME.LOGIN
                )}
                sx={HeaderStyles.loginButton}
              >
                {basicUserInfo ? "Dashboard" : "Login"}
              </Button>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderTopBar;
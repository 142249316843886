import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../redux/features/redux-hooks";
import { resetPassword } from "../../../redux/features/Auth/AuthThunk";
import { Box, Container, Typography, Button, TextField, Link as MuiLink } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from '../../../components/common/SnackBar/snackBar';
import { AxiosError } from 'axios';
import { MuiOtpInput } from "mui-one-time-password-input";
import ShakeSaladLogo from '../../../assets/images/shakesaladlogo.png';
import { sendOtp } from "../../../api/AuthAPI";
import useResetPasswordOtpPageStyles from '../../../styles/PublicPage/resetpasswordotppage';
import { PATH_NAME } from "../../../configs/pathName";

const ResetPasswordOTPPage = () => {
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState("");
    const [otp, setOtp] = useState("");

    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(true);
    const [countdownStarted, setCountdownStarted] = useState(false);

    useEffect(() => {
        if (countdownStarted && timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(countdown);
        } else if (timer === 0) {
            setCanResend(true);
            setCountdownStarted(false);
        }
    }, [timer, countdownStarted]);

    const phone = state?.phone || "";

    const handleResetPassword = async () => {
        if (newPassword && otp) {
            try {
                await dispatch(resetPassword({ new_password: newPassword, otp, full_mobile: phone })).unwrap();
                toast.success("Password reset successfully!");
                navigate(PATH_NAME.LOGIN);
            } catch (error) {
                if (error instanceof AxiosError) {
                    const status = error.response?.status;
                    if (status === 400) {
                        toast.error("Invalid OTP or phone number.");
                    } else if (status === 404) {
                        toast.error("User not found.");
                    } else {
                        toast.error("Failed to reset password.");
                    }
                } else {
                    toast.error("Incorrect OTP.");
                }
            }
        } else {
            toast.error("Please enter both OTP and a new password.");
        }
    };

    const handleResendOtp = async () => {
        try {
            await sendOtp(phone);
            setTimer(30);
            setCanResend(false);
            setCountdownStarted(true);
            toast.success("OTP resent successfully!");
        } catch (error) {
            toast.error("Failed to resend OTP. Please try again.");
        }
    };

    return (
        <Box component="section" sx={useResetPasswordOtpPageStyles.section}>
            <Container maxWidth="xs">
                <Box sx={useResetPasswordOtpPageStyles.logoContainer}>
                    <img
                        alt="Company Logo"
                        src={ShakeSaladLogo}
                        style={{ width: 100 }}
                        />
                </Box>
                <Typography variant="h5" component="h1" align="center" gutterBottom>
                    Reset Your Password
                </Typography>
                <Typography variant="body1" align="center" gutterBottom>
                    Enter the OTP and your new password.
                </Typography>
                <Box sx={useResetPasswordOtpPageStyles.formContainer}>
                    <MuiOtpInput
                        length={6}
                        value={otp}
                        onChange={setOtp}
                        TextFieldsProps={{
                            inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
                            placeholder: '',
                        }}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        sx={useResetPasswordOtpPageStyles.textField}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleResetPassword}
                        sx={useResetPasswordOtpPageStyles.resetButton}
                    >
                        Reset Password
                    </Button>
                    <Typography align="center" sx={useResetPasswordOtpPageStyles.resendText}>
                            Didn’t receive the OTP?{' '}
                            {canResend ? (
                                <MuiLink component="button" onClick={handleResendOtp}>
                                    Resend OTP
                                </MuiLink>
                            ) : (
                                <span>Resend OTP in {timer} seconds</span>
                            )}
                        </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default ResetPasswordOTPPage;

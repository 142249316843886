import React from "react";
import { Avatar, List, Stack, Toolbar, Drawer, IconButton, Typography, Box, ButtonBase } from "@mui/material";
import sizeConfigs from "../../../configs/sizeConfigs";
import colorConfigs from "../../../configs/colorConfigs";
import { PATH_NAME } from "../../../configs/pathName";
import assets from "../../../assets";
import appRoutes from "../../../routes/appRoutes";
import SideBarItem from "./SideBarItem";
import LogoutIcon from '@mui/icons-material/Logout';
import { useAppDispatch } from "../../../redux/features/redux-hooks";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../redux/features/Auth/AuthThunk";

interface SideBarProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const SideBar: React.FC<SideBarProps> = ({ mobileOpen, handleDrawerToggle }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await dispatch(logout()).unwrap();
      navigate(PATH_NAME.LOGIN);
    } catch (e) {
      console.error(e);
    }
  };

  const drawer = (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <List disablePadding>
        <Toolbar sx={{ marginBottom: "40px" }}>
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="center"
          >
            <Avatar
              src={assets.images.logo}
              sx={{
                width: "100px",
                height: "auto",
                borderRadius: "0px",
                paddingTop: "40px",
              }}
            />
          </Stack>
        </Toolbar>
        {appRoutes.map((route, index) => (
          route.sidebarProps ? (
            <SideBarItem item={route} key={index} />
          ) : null
        ))}
      </List>
      <ButtonBase
        focusRipple
        onClick={handleLogout}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          mt: 'auto',
          px: 2,
          pt: 1,
          pb: 1,
          gap: '20px',
          '&:hover': {
            backgroundColor: colorConfigs.sidebar.hoverBg,
          },
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          aria-label="logout"
        >
          <LogoutIcon />
        </IconButton>
        <Typography variant="body1">
          Logout
        </Typography>
      </ButtonBase>
    </Box>
  );

  return (
    <nav>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: sizeConfigs.sidebar.width,
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: sizeConfigs.sidebar.width,
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

export default SideBar;
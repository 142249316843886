import colorConfigs from "../../configs/colorConfigs";

const verifyOtpPageStyles = {
    section: {
        backgroundColor: 'background.default',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        p: 0,
        borderRadius: 2,
        backgroundColor: 'background.paper',
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 2,
    },
    logoImage: {
        width: 100,
    },
    title: {
        fontWeight: 'bold',
        color: '#333',
    },
    subtitle: {
        color: colorConfigs.greytext,
    },
    otpInput: {
        '& .MuiInputBase-input': {
            fontSize: '18px',
            textAlign: 'center',
            color: '#333',
            backgroundColor: '#f7f7f7',
            borderRadius: '8px',
            padding: '12px 14px',
        },
    },
    button: {
        marginTop: 2,
        backgroundColor: colorConfigs.buttons.primary.background,
        color: colorConfigs.buttons.primary.text,
        '&:hover': {
          backgroundColor: colorConfigs.buttons.primary.background,
        },
        },
    resendText: {
        marginTop: 2,
        textAlign: 'center',
    },
};

export default verifyOtpPageStyles;
import { colors } from "@mui/material";

const colorConfigs = {
    primary: "#12582D",          // Primary color
    secondary: "#000000",        // Secondary color
    primaryActiveBg: "#346c43",  // Primary active background
    whitetext:"#ffffff",
    greytext:"aaaaaa",

    // Sidebar
    sidebar: {
        bg: "#12582D",
        color: "#eeeeee",
        hoverBg: "#12582D",
        activeBg: "#346c43",
    },

    // Topbar
    topbar: {
        bg: "#fff",
        color: "#000",
    },

    // Backgrounds
    backgrounds: {
        main: colors.grey["100"],
        light: "#FCF9F7",
        white:"#fff",
        card: "#e0e0e0",
    },

    // Borders
    borders: {
        default: "#e0e0e0",
        qrCode: "#dddddd",
    },

    // Buttons
    buttons: {
        primary: {
            text: "#ffffff",
            background: "#12582D",
            hoverBackground: "#346c43",
        },
        secondary: {
            text: "#000000",
            background: "#eeeeee",
            hoverBackground: "#e0e0e0",
        },
    },

    vouchers:{
        card:{
            background:"#4caf50",
            text:"#fff",
            border:"#fff"
        }
    },

    price:{
        text:"000000",
        amount:"#4CAF50",
        item:"#888888",
        balance:"3a3a3a",
    },

    pagination: {
        color: "#000000",
        activeBg: "#12582D!important",
        activeColor: "#ffffff",
    },

    indicators: {
        activeDot: "#12582D",
        inactiveDot: "#e0e0e0",
    },
};

export default colorConfigs;
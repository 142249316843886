import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import LoginPage from "./components/features/Login/LoginPage";
import RegisterPage from "./components/features/Register/RegisterPage";
import VertifyOTP from "./components/features/Register/VerifyOtpPage";
import HomePage from "./components/features/Home/HomePage";
import DefaultLayout from "./components/layout/DefaultLayout";
import ProtectedLayout from "./components/layout/ProtectedLayout";
import MenuPage from "./components/features/Menu/MenuPage";
import ForgetPasswordPage from "./components/features/Register/ForgetPasswordPage";
import ResetPasswordOTPPage from "./components/features/Register/ResetPasswordOTPPage";
import { PATH_NAME } from "./configs/pathName";
import AboutUsPage from "./components/features/About/AboutUsPage";
import ContactUsPage from "./components/features/Contact/ContactUsPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path={PATH_NAME.ROOT} element={<HomePage />} />
          <Route path={PATH_NAME.ABOUT_US} element={<AboutUsPage />} />
          <Route path={PATH_NAME.CONTACT_US} element={<ContactUsPage />} />
          <Route path={PATH_NAME.MENU} element={<MenuPage />} />
          <Route path={PATH_NAME.LOGIN} element={<LoginPage />} />
          <Route path={PATH_NAME.REGISTER} element={<RegisterPage />} />
          <Route path={PATH_NAME.VERIFY_OTP} element={<VertifyOTP />} />
          <Route path={PATH_NAME.FORGET_PASSWORD} element={<ForgetPasswordPage />} />
          <Route path={PATH_NAME.RESET_PASSWORD_VERIFY} element={<ResetPasswordOTPPage/>} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path={PATH_NAME.MEMBER} element={<MainLayout />}>
            {routes}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
import colorConfigs from "../../configs/colorConfigs";

const loginPageStyles = {
  container: {
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'center',
    height: '100vh',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '8px',
    padding: '24px',
    height:'100vh',
},
  headerText: {
    color: '#136936',
    fontWeight: 500,
  },
  subHeaderText: {
    color: '#a3cf69',
    fontWeight: 500,
  },
  descriptionText: {
    marginTop: '8px',
  },
  phoneInputContainer: {
    mt: 2,
    width: '100%',
  },
  textField: {
    marginTop: '16px',
    width: '100%',
  },
  formActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 2,
  },
  rememberMe: {
    color: 'text.secondary',
  },
  forgotPasswordLink: {
    textDecoration: 'none',
    color: 'primary.main',
  },
  loginButton: {
    mt: 3,
    mb: 2,
    backgroundColor: colorConfigs.buttons.primary.background,
    '&:hover': {
      backgroundColor: colorConfigs.buttons.primary.background,
    },
  },
  signupLink: {
    textDecoration: 'none',
    color: 'primary.main',
  },
};

export default loginPageStyles;
import colorConfigs from "../../configs/colorConfigs";

export const introductionPageStyles = {
    rootPaper: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999,
        display: "flex",
        flexDirection: "column",
        backgroundColor: colorConfigs.backgrounds.light,
    },
    headerBox: {
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundColor: colorConfigs.backgrounds.light,
        zIndex: 10,
        py: 1,
        textAlign: "center",
        borderBottom: `1px solid ${colorConfigs.borders.default}`,
    },
    contentBox: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 4,
        width: "100%",
        textAlign: "center",
        backgroundColor: colorConfigs.backgrounds.light,
    },
    buttonNext: {
        color: colorConfigs.buttons.primary.text,
        borderColor: colorConfigs.buttons.primary.background,
        "&:hover": {
            borderColor: colorConfigs.buttons.primary.background,
            background: colorConfigs.buttons.primary.hoverBackground,
        },
        background: colorConfigs.buttons.primary.background,
        borderRadius: "none",
    },
    dotsContainer: {
        display: "flex",
        justifyContent: "center",
        mt: 2,
    },
    dot: (isActive: boolean) => ({
        width: isActive ? 18 : 8,
        height: 6,
        borderRadius: "30px",
        backgroundColor: isActive
            ? colorConfigs.indicators.activeDot
            : colorConfigs.indicators.inactiveDot,
        margin: "0 3px",
        cursor: "pointer",
        transition: "background-color 0.3s, width 0.3s",
    }),
};

import { fontStyles } from '../../assets/fonts/font';
import colorConfigs from '../../configs/colorConfigs';

const HeaderStyles = {
  appBar: {
    backgroundColor: colorConfigs.backgrounds.white,
    boxShadow: 'none',
    top: 0,
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 20px',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  flex40: {
    flex: '0 0 40%',
  },
  flex20: {
    flex: '0 0 20%',
    justifyContent: 'center',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  button: {
    color: fontStyles.textColor,
    fontSize: fontStyles.fontSizeSmall,
    fontWeight: fontStyles.fontWeightBold,
    fontFamily: fontStyles.primaryFont,
    marginRight: '15px',
    borderRadius: '0px',
    borderBottom: '3px solid transparent',
    '&:hover': {
      borderBottom: `3px solid ${fontStyles.primaryColor}`,
      borderRadius: '0px',
    },
  },
  activeButton: {
    borderBottom: `3px solid ${fontStyles.primaryColor}`,
    color: fontStyles.primaryColor,
  },
  logoButton: {
    padding: 0,
  },
  loginButton: {
    padding: '5px 30px',
    color: colorConfigs.buttons.primary.text,
    background: fontStyles.primaryColor,
    borderRadius: '30px',
    fontSize: fontStyles.fontSizeMedium,
    fontWeight: fontStyles.fontWeightBold,
    fontFamily: fontStyles.primaryFont,
    border: `2px solid ${fontStyles.primaryColor}`,
    '&:hover': {
      backgroundColor: 'white',
      color: fontStyles.primaryColor,
      border: `2px solid ${fontStyles.primaryColor}`,
      borderBottom: `3px solid ${fontStyles.primaryColor}`,
    },
  },
  logo: {
    height: '50px',
    maxWidth: '100%',
  },
  getActiveButtonStyle: (isActive: boolean) => ({
    borderBottom: isActive ? `3px solid ${fontStyles.primaryColor}` : 'none',
  }),
};

export default HeaderStyles;
import colorConfigs from "../../configs/colorConfigs";

export const useResetPasswordOtpPageStyles = {
    section: {
        backgroundColor: 'background.default',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        mb: 2,
    },
    formContainer: {
        mt: 2,
    },
    textField: {
        mt: 2,
    },
    resetButton: {
        mt: 2,
        backgroundColor: colorConfigs.buttons.primary.background,
        color: colorConfigs.buttons.primary.text,
        '&:hover': {
            backgroundColor: colorConfigs.buttons.primary.background,
        },
    },
    resendContainer: {
        textAlign: 'center',
        mt: 2,
    },
    resendText: {
        color: colorConfigs.greytext,
        mt:2,
    },
};

export default useResetPasswordOtpPageStyles;

import React from 'react';
import { CImage } from '@coreui/react';
import assets from '../../../assets';
import '@coreui/coreui/dist/css/coreui.min.css';
import { Button } from '@mui/material';
import HeaderStyles from '../../../styles/PublicPage/header';
import { useAppSelector } from '../../../redux/features/redux-hooks';
import { PATH_NAME } from '../../../configs/pathName';

const banners = [
  {
    image: assets.images.banner1,
    mobileImage: assets.images.banner1,
    getButton: (basicUserInfo: any) => ({
      text: basicUserInfo ? 'Dashboard' : 'Login',
      url: basicUserInfo ? PATH_NAME.MEMBER : PATH_NAME.LOGIN,
    }),
  },
];

const BannerSlider: React.FC = () => {
  const isMobile = window.innerWidth <= 600;
  const basicUserInfo = useAppSelector((state) => state.authState.basicUserInfo);
  const banner = banners[0];

  const { text: buttonText, url: buttonUrl } = banner.getButton(basicUserInfo);

  return (
    <div style={{ position: 'relative' }}>
      <CImage
        className="d-block w-100"
        src={isMobile ? banner.mobileImage : banner.image}
        alt="Banner"
      />
      <div
        style={{
          position: 'absolute',
          bottom: '20%',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 2,
          display: 'flex',
          gap: '20px',
        }}
      >
        <Button
          color="inherit"
          sx={HeaderStyles.loginButton}
          href={buttonUrl}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default BannerSlider;